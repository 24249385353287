<template>
  <div class="container"></div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
export default {
  metaInfo() {
    return {
      title: "SATMocks - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    test_id() {
      return this.$route.query.test_id;
    },
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    test_type() {
      return this.$route.query.test_type;
    }
  },
  watch: {},

  mounted() {
    if (this.test_id && this.user_exam_id && this.token) {
      if (this.test_type.indexOf("ComposePractice") > -1) {
        window.location.replace(
          `https://sat.ivy-way.com/test/composePractices/${this.test_id}?user_exam_id=${this.user_exam_id}&token=${this.token}`
        );
      }
    }
  },

  methods: {}
};
</script>

<style scoped></style>
